@import "variables";

html,
body,
#root {
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.11px;

  #root,
  .ant-modal-root {
    /* button primary */
    button.ant-btn.ant-btn-primary,
    a.ant-btn.ant-btn-primary {
      border-width: 2px;
      font-weight: 500;
      min-height: 35px;
      border-radius: 5px;
    }

    /* button primary ghost */
    button.ant-btn.ant-btn-primary.ant-btn-background-ghost,
    a.ant-btn.ant-btn-primary.ant-btn-background-ghost {
      border-width: 2px;
      font-weight: 500;
      min-height: 35px;
      border-radius: 5px;
    }

    /* button default */
    button.ant-btn.ant-btn-default,
    a.ant-btn.ant-btn-default {
      border-width: 2px;
      font-weight: 500;
      min-height: 35px;
      border-radius: 5px;
      color: #777777;
      border-color: #777777;
    }

    /* button text */
    button.ant-btn.ant-btn-text,
    a.ant-btn.ant-btn-text {
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }

    /* Form Style */
    /* set input border-bottom */
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-number,
    .ant-picker,
    .ant-input-number-input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      border: none;
      border-bottom: 1px solid #777777;
      color: $black;
      background-color: $white;
      text-align: left;

      &.bg-grey {
        background-color: #eeeeee;
      }

      &::placeholder {
        color: #777777;
        font-style: italic;
      }

      &:hover,
      &:focus {
        border: none;
        border-bottom: 1px solid #777777;
        box-shadow: none;
      }

      // disabled input style
      &:disabled {
        color: #777777;
        background-color: #ededed !important;
        border-color: #e0e0e0 !important;

        &::placeholder {
          color: #777777;
        }
      }
    }

    .ant-input-number-input {
      border: none;
      border-bottom: 0px;

      &:hover {
        border: none;
        border-bottom: 0px;
      }
    }

    .ant-select-disabled {
      .ant-select-selector {
        color: #777777 !important;
        background: #e5e5e5 !important;
      }
    }

    .ant-input {
      &::placeholder {
        color: $black;
        font-style: normal;
      }
    }

    .ant-input-number-input {
      &::placeholder {
        color: $black;
        font-style: normal;
      }
    }

    .ant-input-password {
      input.ant-input {
        border: none !important;
        background-color: transparent !important;
      }
    }

    .ant-input-number-group-addon {
      border: none;
      background-color: $white;
      border-bottom: 1px solid #777777;
    }

    .ant-select.bg-grey {
      .ant-select-selector {
        background-color: #eeeeee;
      }
    }

    .ant-select-selection-placeholder,
    span.anticon.anticon-down.ant-select-suffix {
      color: $black;
      text-align: left;
    }

    .ant-checkbox-inner {
      border: 1px solid #000000;
    }

    .ant-input-number-affix-wrapper-disabled,
    .ant-input-affix-wrapper-disabled {
      background-color: #ededed !important;
    }

    .ant-picker-disabled {
      background: #e5e5e5 !important;
    }

    /* End of Form Style */
  }

  /* dropdown list z-index */
  .ant-select-dropdown {
    z-index: 10001;
    overflow-y: scroll !important;
    scrollbar-width: thin;

    // custom
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
    }
  }
}
